import React from "react";
import "./Lp1Footer.css";
const Lp1Footer = () => {
  return (
    <div className="lp1Footer">
      <p>© 2023 TrySEOServices.Com All Rights Reserved.</p>
    </div>
  );
};

export default Lp1Footer;
